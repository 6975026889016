import * as React from "react"
import { Script } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

const IndexPage = () => (
  <Layout>
    <h2>Веб Крафт</h2>
    <p>Закажите у нас разработку проекта любой сложности. Мы сможем помочь на любом участке полного цикла разработки от
      создания макета, до реализации готовых компонентов с последующей их поддержкой и тестированием. Берём проекты
      любой сложности и ценим долгосрочные отношения.</p>
    <div className={styles.contactForm} data-getsitecontrol-inline="280677"></div>
    <h2>
      О нас
    </h2>
    <p>
      Веб Крафт – это нижегородская IT-компания, оказывающая услуги в сфере разработки программного обеспечения. Наш
      профиль - это дизайн и разработка компонентов веб-приложений различной сложности. Высококвалифицированная команда
      разработчиков и веб-дизайнеров позволяет нам осуществлять реализацию и дальнейшую поддержку проектов любой
      сложности.
      С 2013 мы оказываем широкий спектр услуг по разработке веб-приложений и компонентов к ним: разработка
      веб-компонентов и моделирование особенностей построения сложной архитектуры приложений, учёт особенностей
      реализации, дизайн и проработка интерфейса ползователя, прототипирование, разработка с использованием современного
      стека технологий, тестирование.
      Квалифицированная команда нашей компании позволяет нам работать над сложными и интересными проектами и заключать
      долгосрочные контракты с нашими заказчиками.
    </p>
    <h2>Мы нанимаем</h2>
    <p>
      Мы всегда готовы принять в нашу команду квалифицированных сотрудников и предлагаем работу с современным стеком
      технологий.
    </p>
    Приходя к нам вы получаете:
    <ul>
      <li>Интересные проекты и задачи</li>
      <li>Конкурентноспособная заработная плата</li>
      <li>Гибкий график работы</li>
      <li>Комфортный офис в центре города (пл. Горького)</li>
      <li>Пространство для профессионального роста</li>
      <li>Возможность работать из дома</li>
    </ul>
    <p>
      <a href="https://nn.hh.ru/employer/1403217">Наши вакансии</a>
    </p>
    <Script src="https://l.getsitecontrol.com/5wvr2m43.js"/>
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
